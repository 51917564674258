import { boolean, defineAction, number, object, string } from '~/prix'
import updateGeoSchemaAction from './updateGeoSchema.action.json'

const updateGeoSchemaDefinition = defineAction({
  ...updateGeoSchemaAction,
  input: object({
    dataOnly: boolean().label('Código IBGE da cidade.'),
    source: object({
      host: string().label('Endereço do host do banco de dados'),
      port: number().label('Porta do banco'),
      database: string().label('Database do banco'),
      user: string().label('Usuário do banco'),
    }),
    destination: object({
      host: string().label('Endereço do host do banco de dados'),
      port: number().label('Porta do banco'),
      database: string().label('Database do banco'),
      user: string().label('Usuário do banco'),
    }),
  }),
  output: object({}),
})

export default updateGeoSchemaDefinition
