import { FirebaseError } from 'firebase/app'
import { AuthError } from 'firebase/auth'

interface ErrorPayload {
  title?: string
  issues?: string[]
  [key: string]: unknown
}

export class AppError extends Error {
  public key?: string
  public isAppError?: boolean = true
  public payload?: ErrorPayload
  public code?: string | number
  public originalError?: Error
  public autoRetry?: boolean
}

export interface ErrorObject {
  key?: string
  isAppError?: boolean
  message?: string
  payload?: ErrorPayload
  code?: string | number
  autoRetry?: boolean
  originalError?: {
    name: string
    message: string
  }
}

export interface ValidationError extends Error {
  errors?: string[]
}

export interface DatabaseError extends Error {
  code: string
}

interface UnknownError extends Error {
  code: string | number
  UNKNOWN_ERROR: string | number
  PERMISSION_DENIED: string | number
  POSITION_UNAVAILABLE: string | number
  TIMEOUT: string | number
}

export function createError(
  key = 'unknown',
  message: string,
  code = 500,
  payload: ErrorPayload = {},
  originalError?: Error,
  autoRetry = false,
) {
  const newError = new AppError(message)
  newError.key = key
  newError.payload = payload
  newError.code = code
  newError.originalError = originalError
  newError.autoRetry = autoRetry
  return newError
}

const errors = {
  notFound: (customMessage?: string, payload?: ErrorPayload, originalError?: Error) =>
    createError('notFound', customMessage || 'Não encontrado', 404, payload, originalError),
  conflict: (customMessage?: string, payload?: ErrorPayload, originalError?: Error) =>
    createError(
      'conflict',
      customMessage || 'Ocorreu um conflito, verifique se as informações fornecidas já não constam',
      409,
      payload,
      originalError,
    ),
  security: (customMessage?: string, payload?: ErrorPayload, originalError?: Error) =>
    createError('security', customMessage || 'Não autorizado', 403, payload, originalError),
  permission: (customMessage?: string, payload?: ErrorPayload, originalError?: Error) =>
    createError('permission', customMessage || 'Sem permissão', 401, payload, originalError),
  timeout: (customMessage?: string, payload?: ErrorPayload, originalError?: Error) =>
    createError(
      'timeout',
      customMessage || 'O tempo limite expirou, tente novamente mais tarde',
      408,
      payload,
      originalError,
    ),
  geolocationFailed: (customMessage?: string, payload?: ErrorPayload, originalError?: Error) =>
    createError(
      'geolocationFailed',
      customMessage || 'Problema buscando a geolocalização',
      401,
      payload,
      originalError,
    ),
  auth: (customMessage?: string, payload?: ErrorPayload, originalError?: Error) =>
    createError('auth', customMessage || 'Não autenticado', 401, payload, originalError),
  connectionFailed: (customMessage?: string, payload?: ErrorPayload, originalError?: Error) =>
    createError(
      'connectionFailed',
      customMessage || 'Problema de conexão',
      503,
      payload,
      originalError,
    ),
  abort: (customMessage?: string, payload?: ErrorPayload, originalError?: Error) =>
    createError('abort', customMessage || 'Requisição cancelada', 499, payload, originalError),
  validation: (customMessage?: string, payload?: ErrorPayload, originalError?: Error) =>
    createError('validation', customMessage || 'Inválido', 400, payload, originalError),
  incompatible: (customMessage?: string, payload?: ErrorPayload, originalError?: Error) =>
    createError(
      'incompatible',
      customMessage || 'Este recurso não é compatível',
      406,
      payload,
      originalError,
    ),
  unknown: (customMessage?: string, payload?: ErrorPayload, originalError?: Error) =>
    createError('unknown', customMessage || 'Erro desconhecido', 500, payload, originalError),
  tooManyRequests: (customMessage?: string, payload?: ErrorPayload, originalError?: Error) =>
    createError(
      'tooManyRequests',
      customMessage || 'Muitas requisições feitas',
      429,
      payload,
      originalError,
    ),
}

export const naturalErrors = ['notFound', 'connectionFailed', 'abort', 'validation', 'permission']

export function fromUnknownError(
  error:
    | AppError
    | Error
    | ErrorObject
    | ValidationError
    | FirebaseError
    | DatabaseError = errors.unknown(),
  payload = {},
): AppError {
  if ((error as AppError).isAppError) {
    return error as AppError
  }

  const message = error.message || String(error)

  if ((error as ValidationError).name === 'ValidationError') {
    return errors.validation('Existem erros de validação', {
      issues: (error as ValidationError).errors,
    })
  }

  if (/chunk/i.exec(message)) {
    return errors.connectionFailed(
      'Recurso indisponível offline, verifique sua rede',
      payload,
      error as Error,
    )
  }

  if (/Failed\sto\sfetch/i.exec(message)) {
    return errors.connectionFailed(
      'Problema na conexão, verifique sua rede',
      payload,
      error as Error,
    )
  }

  if ((error as DatabaseError).code === '23505') {
    return errors.conflict(
      'Conflito, já existe registro com essas definições',
      payload,
      error as DatabaseError,
    )
  }

  if ((error as UnknownError).code === (error as UnknownError).UNKNOWN_ERROR) {
    return errors.unknown(undefined, payload, error as Error)
  }

  if ((error as UnknownError).code === (error as UnknownError).PERMISSION_DENIED) {
    return errors.incompatible(
      'Você precisa dar permissão para usar este recurso',
      payload,
      error as Error,
    )
  }

  if ((error as UnknownError).code === (error as UnknownError).POSITION_UNAVAILABLE) {
    return errors.geolocationFailed(
      'A localização de GPS não está disponível',
      payload,
      error as Error,
    )
  }

  if ((error as UnknownError).code === (error as UnknownError).TIMEOUT) {
    return errors.timeout('A localização de GPS não está disponível', payload, error as Error)
  }

  if (/timeout/i.exec(message)) {
    return errors.connectionFailed('Tempo esgotado', payload, error as Error)
  }

  if ((error as AuthError).code === 'auth/id-token-expired') {
    return errors.auth('Sessão expirada, faça login novamente', payload, error as Error)
  }

  if ((error as AuthError).code === 'auth/user-not-found') {
    return errors.auth('Usuário não encontrado', payload, error as Error)
  }

  if (
    (error as AuthError).code === 'auth/invalid-password' ||
    (error as AuthError).code === 'auth/wrong-password'
  ) {
    return errors.auth('Senha incorreta', payload, error as Error)
  }

  console.warn('New unknown error entries', error, error ? Object.entries(error) : null)

  return errors.unknown(undefined, payload, error as Error)
}

export function objectToError(errorObject: ErrorObject = errors.unknown()): AppError {
  const code =
    typeof errorObject.code === 'string' ? parseInt(errorObject.code, 10) : errorObject.code
  return createError(
    errorObject.key || 'unknown',
    errorObject.message || 'Erro desconhecido',
    code || 500,
    errorObject.payload || {},
    errorObject.originalError,
    errorObject.autoRetry || false,
  )
}

export function errorToObject(error: Error = errors.unknown(), safe = true): ErrorObject {
  const { key, message, isAppError, payload, code, originalError, autoRetry } =
    fromUnknownError(error)

  return {
    key,
    isAppError,
    message,
    payload,
    code,
    autoRetry,
    originalError:
      !safe && originalError
        ? {
            name: originalError.name,
            message: originalError.message,
          }
        : undefined,
  }
}

export default errors
