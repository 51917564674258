import { array, number, boolean, defineAction, object, string, enumerated } from '~/prix'
import legalEntityMassGeocodeAction from './legalEntityMassGeocode.action.json'

const legalEntityMassGeocodeDefinition = defineAction({
  ...legalEntityMassGeocodeAction,
  input: object({
    concurrency: number().label('Quantidade de cidades a se processar de forma concorrente.'),
    level: enumerated({
      macroRegion: 'macroRegion',
      mesoRegion: 'mesoRegion',
      microRegion: 'microRegion',
      state: 'state',
      city: 'city',
    }).isOptional(),
    ibgeCodes: string(),
    force: boolean()
      .isOptional()
      .label(
        'Geocodificar todos os endereços na legal_entity e não somente os nulos ou desatualizados.',
      ),
    debug: boolean().isOptional(),
    geocodeWhere: string().isOptional(),
  }),
  output: object({}),
})

export default legalEntityMassGeocodeDefinition
