import { defineAction, object, string } from '~/prix'
import geocodeAddressAction from './geocodeAddress.action.json'

// city, country, neighborhood, number, state, street

const geocodeAddressDefinition = defineAction({
  ...geocodeAddressAction,
  input: object({
    address: string().label('Endereço completo a ser geocodificado.'),
  }),
  output: object({}),
})

export default geocodeAddressDefinition
