import { array, boolean, defineAction, object, string } from '~/prix'
import systemPropagateToOlapAction from './systemPropagateToOlap.action.json' assert { type: 'json' }
const systemPropagateToOlapDefinition = defineAction({
  ...systemPropagateToOlapAction,
  input: object({
    useCustomTableList: boolean(),
    tablesAndSchemas: array(string()),
  }),
  output: object({}),
})
export default systemPropagateToOlapDefinition
